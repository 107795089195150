/*.about{
    padding-top: 120px;
    
    padding-left: 80px;
    text-align: center;
}
.founder{
    display: absolute;
    height: 200px;
    width: 180px;
    

}
.image{
    height: 100%;
    width: 100%;
    border-radius: .5rem;
}
*/
.about{
    padding-top: 120px;
    
    padding-left: 80px;
    text-align: center;
}
.image{
    height: 100%;
    width: 100%;
    border-radius: .5rem;
}

.image{
    height: 200px;
    width: 150px;
    padding: 0;
    overflow: hidden;


}


.about h{
    padding-bottom: 100px;

    font-size: 30px;
}
.about p{
    text-align: left;
}
.pro{
    text-align: left;
}




