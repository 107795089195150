
:root{

--PrimaryColor: hsl(199, 100%, 33%);

--SecondaryColor: hsl(187, 85%, 43%);

--gradientColor: linear-gradient(to right, hsl(187,85%, 43%), hsl(199, 100%, 33%));

--whiteColor: hsl(8, 8%, 100%);

--blackColor: hsl(201, 33%, 16%);

--textColor: hsl(240, 4%, 36%);

--whiteColorDeam: hsl(0, 0%, 93%);

--greyText: rgb(190, 198, 190);

--inputColor: rgb(239, 239, 239);

--bodyColor: rgb(248, 240, 246);

--cardBG: rgb(225, 225, 235);
--cardHover:rgb(255,255,255);
}

.Body{
    width: 100%;

    
}
.title{
        position: relative;
        color:var(--textColor);
        margin: 1rem 0;
        z-index: 2;

}
.title ::after{
    position: absolute;
    content: '';
    background: var(--SecondaryColor);
    height: 5px;
    width: 120px;
    right: 0;
    bottom: 5px;
    z-index: -1;
}
.secContent_grid{
    
    display: grid;
    justify-content: center;
    gap: 1.5rem;

}
.singleProject{
    height: 100%;
    display: grid;
    row-gap: 10px;
    
    border-radius: 10px;
    align-items: center;
    background: var(--cardBG);
    box-shadow: 0 2px 4px rgba(140, 140, 141, 0.549);
    overflow: hidden;
    
}

.singleProject:hover{
    background: var(--cardHover);
    box-shadow: 1 4px 4px rgba(85, 85, 114, 0.549);
    transition: .3s ease;

}
.imageDiv{
    height: 180px;
    width: 100%;
    padding: 0;
    overflow: hidden;

    


}

img{
    padding: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: 2s ease;
}


img:hover{
        transform: scale(1.1);
    }

.cardInfo{
    padding: 1rem;
}

.prjTitle{
    color: var(--blackColor);
    font-size: 1.25rem;

}
.content_flex{
    display: flex;
    gap: .5rem;

}
.icon{
    color: var(--textColor);
    font-size: 18px;

}
.name{
    color: var(--textColor);
    font-weight: 400;
    font-size: 14px;

}
.price_flex{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1rem;
    margin: 1rem 0;
    border-top: 1.5px solid var(--greyText);
    border-bottom: 1.5px solid var(--greyText);
}
.grade{
    color: var(--textColor);
    max-width: 100px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
small{
    text-align: center;
    background: var(--gradientColor);
    border-radius: 1rem;
    color: var(--whiteColor);
    padding: 1px 10px;
    font-size: 10px;
}
.price{
    font-size: 2rem;
    color: var(--blackColor);
}
.desc{
    font-size: 13.5px;
    color: var(--textColor);
    font-weight: 500;
}

.icon{
    align-self: center;
    font-size: 18px;

}
.btn_flex{
    display: flex;
    background: var(--gradientColor);
    width: max-content;
    bottom: -0.7rem;
    column-gap: .8rem;
    padding: 5px 1rem;
    border-radius: 3rem;
    margin-top: 1rem;
    color: var(--whiteColor);
    font-weight: 600;
    justify-content: space-between;
    gap: .5rem;
}

@media screen  and (min-width: 500px) {
    .title{
        font-size: 1.5rem;

    }
    .title::after{
        width: 155px !important;
    }
    .secContent_grid{
        grid-template-columns: repeat(2,1fr);
        
    }
    .singleProject{
        height: auto;
    }

    

        
    
    
}
@media screen and (min-width:840px) {
.secContent_grid{
    grid-template-columns: repeat(5,1fr);
    
}
.singleProject{
    height: auto;
}
}
