*{
    
    font-family: "poppins",sans-serif;
}
.contact{
    background: pink;


}
.containe {
    padding-left: 80px;
    
    height: 130vh;
    width: 100%;
    padding-top: 180px;
    
    
}

.left-side{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.left-side i{
    font-size: 30px;
    color: #3e2093;
}