.container-fluid{
    padding: 0;
    
    
}
.container-fluid h3{
    font-family: serif;
}
.container-fluid p{
    font-family: none;
}